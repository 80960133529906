import React, { useState } from 'react';
import './footer.css';

import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/icomoon/facebook';
import { linkedin } from 'react-icons-kit/icomoon/linkedin';
import { instagram } from 'react-icons-kit/icomoon/instagram';
import { twitter } from 'react-icons-kit/icomoon/twitter';

export default function Footer({ props }) {
  const navigateTo = (route) => {
    props.history.push(route);
  };
  return (
    <div className='footer-container '>
      <div className='footer-side-left'>
        <h5>Help Us raise the vibes by sharing your vision!</h5>

        <div
          className='footer-left-side-btn'
          onClick={() => navigateTo('/contact')}
        >
          CONTACT US
        </div>
      </div>
      <div className='footer-side-right'>
        <div className='footer-box-1'>
          <div className='footer-heading'>Quick Link</div>
          <div className='footer-links' onClick={() => navigateTo('/products')}>
            Products
          </div>
          <div className='footer-links' onClick={() => navigateTo('/poetry')}>
            Poetry
          </div>
          <div className='footer-links' onClick={() => navigateTo('/about')}>
            About
          </div>
          <div className='footer-links' onClick={() => navigateTo('/contact')}>
            Contact Us
          </div>
          <div className='footer-links' onClick={() => navigateTo('/music')}>
            Music
          </div>
        </div>
        <div className='footer-box-2'>
          <div className='footer-heading'>Say Hello</div>
          <div className='footer-links'>JavonBenzel@vibez4visionairiez.com</div>
          <div className='footer-links'>Submissions@vibez4visionairiez.com</div>
          <div className='footer-links'>Info@vibez4visionairiez.com</div>
          <div className='footer-social-links'>
            <div>
              <Icon icon={facebook} size={15} />
            </div>
            <div>
              <Icon icon={linkedin} size={15} />
            </div>
            <div>
              <Icon icon={instagram} size={15} />
            </div>
            <div>
              <Icon icon={twitter} size={15} />
            </div>
          </div>
          {/* <div className='footer-copyright'>
            Copyright © 2021. All Rights Reserved.
          </div> */}
        </div>
      </div>
    </div>
  );
}
