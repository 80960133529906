import React, { useState } from 'react';
import Banner from '../../components/banner/banner';
import Footer from '../../components/Footer/footer';
import Header from '../../components/Header/header';

export default function Music(props) {
  return (
    <div>
      <Header props={props} />
      <Banner />
      <div
        className='poetry-container'
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
          margin: '3% 0',
        }}
      >
        <h1 style={{ fontSize: 30 }}>
          More Vibez & Visions Coming Soon!!! Contact
          Submissions@Vibez4Visionairiez.com to get featured here!
        </h1>
      </div>

      <Footer props={props} />
    </div>
  );
}
