import React, { useState } from 'react';
import './banner.css';

export default function Banner({ title }) {
  return (
    <div className='banner-container'>
      <h2>{title}</h2>{' '}
    </div>
  );
}
