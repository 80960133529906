import './App.css';
import Home from './screeens/home/home';
import AboutUs from './screeens/AboutUs/aboutus';
import ContactUs from './screeens/contactus/contact';
import Poetry from './screeens/poetry/poetry';
import Music from './screeens/music/music';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Products from './screeens/Products/products';

function App() {
  return (
    <>
      {/* <Header /> */}
      <Router>
        <div>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={AboutUs} />
          <Route exact path='/contact' component={ContactUs} />
          <Route exact path='/poetry' component={Poetry} />
          <Route exact path='/music' component={Music} />
          <Route exact path='/products' component={Products} />
        </div>
      </Router>
    </>
  );
}

export default App;
