import React from 'react';
import Banner from '../../components/banner/banner';
import Divider from '../../components/divider/divider';
import ArtistCard2 from '../../components/artists/artistCard2';

import './home.css';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';

export default function Home(props) {
  const artistList = [
    {
      name: 'Mr.Sleep Fah Me (Poet,Spoken Word Artist)',
      poem: 'Poem: All Aboard ',
      link: 'https://youtu.be/pCzVdTdZIOE',
      image: require('../../assets/artists images/SleepFahMe.jpg'),
      contact: {
        instagram: '@Mr_Sleep_Fah_Me',
        instaLink: 'https://instagram.com/mr_sleep_fah_me?utm_medium=copy_link',
        email: 'Defpoet65@gmail.com',
      },
    },
    {
      name: 'Macadon Blackheart (Rapper & Spoken Word Artist)',
      poem: 'Poem: January 28, 2021 ',
      link: 'https://youtube.com/watch?v=J3Vx_QAbYFU&feature=share',
      image: require('../../assets/artists images/MacDaDon.jpeg'),
      contact: {
        instagram: '@macadonbk',
        instaLink: 'https://instagram.com/macadonbk?utm_medium=copy_link',
        email: 'Defpoet65@gmail.com',
      },
    },
    {
      name: 'Lethal Passion:(Pouring into others through creative expression with a major emphasis in Poetry)',
      poem: 'Poem : Kindred Spirit ',
      link: 'https://youtube.com/watch?v=J3Vx_QAbYFU&feature=share',
      image: require('../../assets/artists images/LethalPoet.jpg'),
      contact: {
        instagram: '@l3thalpo3t',
        instaLink: 'https://instagram.com/l3thalpo3t?utm_medium=copy_link',
        email: '',
      },
    },

    {
      name: 'Javon Benzel (Hip Hop & Poetry)',
      poem: 'Song Title: BlkKngz ',
      link: 'https://youtube.com/watch?v=J3Vx_QAbYFU&feature=share',
      image: require('../../assets/artists images/JavonBenzel.jpg'),
      contact: {
        instagram: '@Javonbenzel_v4v',
        instaLink: 'https://instagram.com/javonbenzel_v4v?utm_medium=copy_link',
        email: '',
      },
    },
    {
      name: 'Mika’EL Ben Or',
      poem: 'Project Title: The Holy Meditation Album',
      link: 'https://soundcloud.app.goo.gl/F5gAhF5Jfy3LuycUA',
      image: require('../../assets/artists images/Mika’EL Ben.jpeg'),
      contact: {
        instagram: '@the.edenic.experience',
        instaLink:
          'https://instagram.com/the.edenic.experience?utm_medium=copy_link',
        email: '',
      },
    },
  ];
  return (
    <div>
      <Header props={props} />
      <Banner />
      <Divider />
      <div className='artist-list'>
        {artistList.map((val, i) => (
          <ArtistCard2 val={val} key={i} />
        ))}
      </div>
      <Footer props={props} />
    </div>
  );
}
