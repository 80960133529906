import React, { useState } from 'react';
import './products.css';
export default function ProductCard({ product }) {
  return (
    <div className='product-card-container'>
      <div className='product-image-container'>
        <img src={product.image} />
      </div>
      <div
        className='product-card-detail'
        dangerouslySetInnerHTML={{ __html: product.form }}
      >
        {}
      </div>
    </div>
  );
}
