import React, { useState } from 'react';
import './header.css';
import { Drawer, Button, Radio, Space } from 'antd';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/icomoon/menu';

export default function Header({ props }) {
  const [state, setState] = useState({
    visible: false,
  });
  const navigateTo = (route) => {
    props.history.push(route);
  };

  const showDrawer = () => {
    setState({
      visible: true,
    });
  };

  const onClose = () => {
    setState({
      visible: false,
    });
  };
  return (
    <>
      <Drawer
        title='Basic Drawer'
        placement='right'
        closable={false}
        onClose={onClose}
        visible={state.visible}
      >
        <div
          onClick={() => navigateTo('/')}
          className='drawer-list'
          style={{
            color: props.location.pathname === '/' ? '#bd9651' : null,
          }}
        >
          Home / Visionary Highlights
        </div>

        <div
          onClick={() => navigateTo('/poetry')}
          className='drawer-list'
          style={{
            color: props.location.pathname === '/poetry' ? '#bd9651' : null,
          }}
        >
          Poets
        </div>
        <div
          onClick={() => navigateTo('/music')}
          className='drawer-list'
          style={{
            color: props.location.pathname === '/music' ? '#bd9651' : null,
          }}
        >
          Musicians
        </div>
        <div
          onClick={() => navigateTo('/about')}
          className='drawer-list'
          style={{
            color: props.location.pathname === '/about' ? '#bd9651' : null,
          }}
        >
          About Us
        </div>
        <div
          onClick={() => navigateTo('/contact')}
          className='drawer-list'
          style={{
            color: props.location.pathname === '/contact' ? '#bd9651' : null,
          }}
        >
          Contact Us
        </div>
        <div
          onClick={() => navigateTo('/products')}
          style={{
            color: props.location.pathname === '/products' ? '#bd9651' : null,
          }}
          className='drawer-list'
        >
          Products
        </div>
      </Drawer>
      <div className='header-container mobile-H laptop-H desktop-H desktop-2k'>
        {/* <div className='header-logo'>
          <img src={require('../../assets/images/white-logo.png')} />
        </div> */}
        <div className='burger-icon'>
          <Icon
            onClick={showDrawer}
            icon={menu}
            size={40}
            style={{ color: '#000' }}
          />
        </div>
        <div className='nav'>
          <div
            onClick={() => navigateTo('/')}
            className='nav-links'
            style={{
              color: props.location.pathname === '/' ? '#bd9651' : null,
            }}
          >
            Home / Visionary Highlights
          </div>

          <div
            onClick={() => navigateTo('/poetry')}
            className='nav-links'
            style={{
              color: props.location.pathname === '/poetry' ? '#bd9651' : null,
            }}
          >
            Poets
          </div>
          <div
            onClick={() => navigateTo('/music')}
            style={{
              color: props.location.pathname === '/music' ? '#bd9651' : null,
            }}
            className='nav-links'
          >
            Musicians
          </div>
          <div
            onClick={() => navigateTo('/about')}
            className='nav-links'
            style={{
              color: props.location.pathname === '/about' ? '#bd9651' : null,
            }}
          >
            About
          </div>
          <div
            onClick={() => navigateTo('/products')}
            className='nav-links'
            style={{
              color: props.location.pathname === '/products' ? '#bd9651' : null,
            }}
          >
            Products
          </div>
          <div
            onClick={() => navigateTo('/contact')}
            className='nav-links'
            style={{
              color: props.location.pathname === '/contact' ? '#bd9651' : null,
            }}
          >
            Contact Us
          </div>
        </div>
      </div>
    </>
  );
}
