import React, { useState } from 'react';
import Banner from '../../components/banner/banner';
import Footer from '../../components/Footer/footer';
import Header from '../../components/Header/header';
import ProductCard from './productCard';

import { Select } from 'antd';

const { Option, OptGroup } = Select;

export default function Products(props) {
  const [state, setState] = useState({
    category: 'all',
    productsData: [
      {
        title: 'Graphic Card',
        price: '$200',
        image: require('../../assets/artists images/book.jpeg'),
        category: 'book',
        form: `<form
        action='https://www.paypal.com/cgi-bin/webscr'
        method='post'
        target='_top'
      >
        <input type='hidden' name='cmd' value='_s-xclick' />
        <input
          type='hidden'
          name='hosted_button_id'
          value='CSB2UEAL58GFU'
        />
        <input
          type='image'
          src='https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif'
          border='0'
          name='submit'
          alt='PayPal - The safer, easier way to pay online!'
        />
        <img
          alt=''
          border='0'
          src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif'
          width='1'
          height='1'
        />
      </form>`,
      },
      {
        title: 'Graphic Card',
        price: '$500',
        image: require('../../assets/artists images/t-shirt.jpeg'),
        category: 't-shirt',
        form: `<form
        action='https://www.paypal.com/cgi-bin/webscr'
        method='post'
        target='_top'
      >
        <input type='hidden' name='cmd' value='_xclick' />
        <input
          type='hidden'
          name='business'
          value='Vibez4visionairiez@gmail.com'
        />
        <input type='hidden' name='lc' value='US' />
        <input
          type='hidden'
          name='item_name'
          value="'V4V' Logo Short Sleeve T-Shirt"
        />
        <input type='hidden' name='button_subtype' value='services' />
        <input type='hidden' name='no_note' value='0' />
        <input type='hidden' name='currency_code' value='USD' />
        <input type='hidden' name='shipping' value='15.00' />
        <input
          type='hidden'
          name='bn'
          value='PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest'
        />
        <table>
          <tr>
            <td>
              <input
                type='hidden'
                name='on0'
                value="'V4V' Logo Short Sleeve T-Shirt"
              />
              "V4V" Logo Short Sleeve T-Shirt
            </td>
          </tr>
          <tr>
            <td>
              <select name='os0'>
                <option value='WHT - SM'>WHT - SM $30.00 USD</option>
                <option value='BLK - SM'>BLK - SM $30.00 USD</option>
                <option value='WHT - M'>WHT - M $30.00 USD</option>
                <option value='BLK - M'>BLK - M $30.00 USD</option>
                <option value='WHT - L'>WHT - L $30.00 USD</option>
                <option value='BLK - L'>BLK - L $30.00 USD</option>
                <option value='WHT - XL'>WHT - XL $30.00 USD</option>
                <option value='BLK - XL'>BLK - XL $30.00 USD</option>
                <option value='WHT - XXL'>WHT - XXL $30.00 USD</option>
                <option value='BLK - XXL'>BLK - XXL $30.00 USD</option>
              </select>{' '}
            </td>
          </tr>
        </table>
        <input type='hidden' name='option_select0' value='WHT - SM' />
        <input type='hidden' name='option_amount0' value='30.00' />
        <input type='hidden' name='option_select1' value='BLK - SM' />
        <input type='hidden' name='option_amount1' value='30.00' />
        <input type='hidden' name='option_select2' value='WHT - M' />
        <input type='hidden' name='option_amount2' value='30.00' />
        <input type='hidden' name='option_select3' value='BLK - M' />
        <input type='hidden' name='option_amount3' value='30.00' />
        <input type='hidden' name='option_select4' value='WHT - L' />
        <input type='hidden' name='option_amount4' value='30.00' />
        <input type='hidden' name='option_select5' value='BLK - L' />
        <input type='hidden' name='option_amount5' value='30.00' />
        <input type='hidden' name='option_select6' value='WHT - XL' />
        <input type='hidden' name='option_amount6' value='30.00' />
        <input type='hidden' name='option_select7' value='BLK - XL' />
        <input type='hidden' name='option_amount7' value='30.00' />
        <input type='hidden' name='option_select8' value='WHT - XXL' />
        <input type='hidden' name='option_amount8' value='30.00' />
        <input type='hidden' name='option_select9' value='BLK - XXL' />
        <input type='hidden' name='option_amount9' value='30.00' />
        <input type='hidden' name='option_index' value='0' />
        <input
          type='image'
          src='https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif'
          border='0'
          name='submit'
          alt='PayPal - The safer, easier way to pay online!'
        />
        <img
          alt=''
          border='0'
          src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif'
          width='1'
          height='1'
        />
      </form>`,
      },
    ],
  });

  const handleChange = (value) => {
    let filterProducts = [];
    let originalProducts = [
      {
        title: 'Graphic Card',
        price: '$200',
        image: require('../../assets/artists images/book.jpeg'),
        category: 'book',
        form: `<form
        action='https://www.paypal.com/cgi-bin/webscr'
        method='post'
        target='_top'
      >
        <input type='hidden' name='cmd' value='_s-xclick' />
        <input
          type='hidden'
          name='hosted_button_id'
          value='CSB2UEAL58GFU'
        />
        <input
          type='image'
          src='https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif'
          border='0'
          name='submit'
          alt='PayPal - The safer, easier way to pay online!'
        />
        <img
          alt=''
          border='0'
          src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif'
          width='1'
          height='1'
        />
      </form>`,
      },
      {
        title: 'Graphic Card',
        price: '$500',
        image: require('../../assets/artists images/t-shirt.jpeg'),
        category: 't-shirt',
        form: `<form
        action='https://www.paypal.com/cgi-bin/webscr'
        method='post'
        target='_top'
      >
        <input type='hidden' name='cmd' value='_xclick' />
        <input
          type='hidden'
          name='business'
          value='Vibez4visionairiez@gmail.com'
        />
        <input type='hidden' name='lc' value='US' />
        <input
          type='hidden'
          name='item_name'
          value="'V4V' Logo Short Sleeve T-Shirt"
        />
        <input type='hidden' name='button_subtype' value='services' />
        <input type='hidden' name='no_note' value='0' />
        <input type='hidden' name='currency_code' value='USD' />
        <input type='hidden' name='shipping' value='15.00' />
        <input
          type='hidden'
          name='bn'
          value='PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest'
        />
        <table>
          <tr>
            <td>
              <input
                type='hidden'
                name='on0'
                value="'V4V' Logo Short Sleeve T-Shirt"
              />
              "V4V" Logo Short Sleeve T-Shirt
            </td>
          </tr>
          <tr>
            <td>
              <select name='os0'>
                <option value='WHT - SM'>WHT - SM $30.00 USD</option>
                <option value='BLK - SM'>BLK - SM $30.00 USD</option>
                <option value='WHT - M'>WHT - M $30.00 USD</option>
                <option value='BLK - M'>BLK - M $30.00 USD</option>
                <option value='WHT - L'>WHT - L $30.00 USD</option>
                <option value='BLK - L'>BLK - L $30.00 USD</option>
                <option value='WHT - XL'>WHT - XL $30.00 USD</option>
                <option value='BLK - XL'>BLK - XL $30.00 USD</option>
                <option value='WHT - XXL'>WHT - XXL $30.00 USD</option>
                <option value='BLK - XXL'>BLK - XXL $30.00 USD</option>
              </select>{' '}
            </td>
          </tr>
        </table>
        <input type='hidden' name='option_select0' value='WHT - SM' />
        <input type='hidden' name='option_amount0' value='30.00' />
        <input type='hidden' name='option_select1' value='BLK - SM' />
        <input type='hidden' name='option_amount1' value='30.00' />
        <input type='hidden' name='option_select2' value='WHT - M' />
        <input type='hidden' name='option_amount2' value='30.00' />
        <input type='hidden' name='option_select3' value='BLK - M' />
        <input type='hidden' name='option_amount3' value='30.00' />
        <input type='hidden' name='option_select4' value='WHT - L' />
        <input type='hidden' name='option_amount4' value='30.00' />
        <input type='hidden' name='option_select5' value='BLK - L' />
        <input type='hidden' name='option_amount5' value='30.00' />
        <input type='hidden' name='option_select6' value='WHT - XL' />
        <input type='hidden' name='option_amount6' value='30.00' />
        <input type='hidden' name='option_select7' value='BLK - XL' />
        <input type='hidden' name='option_amount7' value='30.00' />
        <input type='hidden' name='option_select8' value='WHT - XXL' />
        <input type='hidden' name='option_amount8' value='30.00' />
        <input type='hidden' name='option_select9' value='BLK - XXL' />
        <input type='hidden' name='option_amount9' value='30.00' />
        <input type='hidden' name='option_index' value='0' />
        <input
          type='image'
          src='https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif'
          border='0'
          name='submit'
          alt='PayPal - The safer, easier way to pay online!'
        />
        <img
          alt=''
          border='0'
          src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif'
          width='1'
          height='1'
        />
      </form>`,
      },
    ];

    setState({ ...state, category: value });

    console.log(value);

    originalProducts.filter((val) => {
      if (val.category === value) {
        filterProducts.push(val);

        setState({
          ...state,
          productsData: filterProducts,
          category: value,
        });

        console.log(filterProducts);
      }
    });

    if (value === 'all') {
      setState({ ...state, productsData: originalProducts });
    }
  };
  return (
    <div>
      <Header props={props} />
      <Banner />
      <div
        className='mobile-H laptop-H desktop-H'
        style={{
          margin: '20px 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Select
          defaultValue='all'
          style={{ width: 300, alignSelf: 'flex-end' }}
          onChange={handleChange}
        >
          <OptGroup label='Category'>
            <Option value='all'>All</Option>
            <Option value='t-shirt'>T-Shirts</Option>
            <Option value='book'>Books</Option>
          </OptGroup>
        </Select>
      </div>
      <div className='product-container desktop-H'>
        {state.productsData.map((val, i) => (
          <ProductCard product={val} key={i} />
        ))}
      </div>
      {/* <div
        className='poetry-container'
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
          margin: '3% 0',
        }}
      >
        <h1 style={{ fontSize: 60 }}>Products Section</h1>
      </div> */}
      <Footer props={props} />
    </div>
  );
}
