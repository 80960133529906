import React from 'react';
import './style.css';
export default function ArtistCard2({ val }) {
  return (
    <div className='artist-card2-container'>
      <div className='card-image-container'>
        <img src={val.image} />
      </div>
      <div className='artists-card2-details'>
        <div className='artist-card-bottom-animation'></div>
        <h3>{val.name}</h3>
        <div>
          <p>{val.poem}</p>
          <p>
            Instagram:{' '}
            <a href={val.contact.instaLink} target='_blank'>
              {val.contact.instagram}
            </a>
          </p>
          <p>
            Email:{' '}
            <a href={`mailto:${val.contact.email}`}>{val.contact.email}</a>
          </p>
        </div>
        <a className='artist-card-btn' href={val.link} target='_blank'>
          Link Here
        </a>
      </div>
    </div>
  );
}
