import React, { useState } from 'react';
import Banner from '../../components/banner/banner';
import Footer from '../../components/Footer/footer';
import Header from '../../components/Header/header';
import './aboutus.css';

export default function AboutUs(props) {
  return (
    <div>
      <Header props={props} />
      <Banner />

      <div className='about-us-top-section  mobile-H laptop-H desktop-H'>
        <div className='top-section-image-container'>
          <img src={require('../../assets/images/about.jpg')} />
        </div>
        <div className='about-top-section-detail '>
          <div>
            <h3>About</h3>
            <p>
              As an artist myself, I realized there was a major gap between
              mainstream media, and conscious "thought provoking" content. With
              all that seemed to be going on in the world, there was not anyone
              taking a real social stance. This created a strong desire for
              expression, and a safe haven everyone could be "unfiltered", all
              the while, changing lives.
              <br />
              <br />
              That was the birth of "Vibez4Visionairiez", the platform geared
              towards speaking "truth to power" and promoting awareness within
              our communities. Whether you are taking a political stance, or
              your content is strictly spiritual based, its only right your
              voice is heard. All our content is intended to re-generate well
              needed conversations, and restore vision. V4V" is not where you
              speak "your" truth, but an individual perspective of "one truth",
              raising vibrations one artist at a time.
              <br />
              <br />
              All prasies to the most high..
            </p>
          </div>
          <div className='about-top-section-sub-heading'>
            {/* <div>
              <h4>Who we are</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad
                corrupti dicta voluptatem delectus sunt quam magnam
                exercitationem aut, laboriosam natus obcaecati voluptas harum
                architecto tempore, id illo similique nulla aspernatur?
              </p>
            </div>
            <div>
              <h4>Who we are</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. In
                inventore rem natus repudiandae pariatur dolores eligendi
                veritatis necessitatibus, incidunt temporibus, earum magni,
                porro accusantium possimus a nostrum nesciunt voluptatibus iure!
              </p>
            </div> */}
          </div>
        </div>
      </div>

      <div className='about-bottom-container'>
        <h2>How to support! </h2>

        <li>
          1. Be sure to check out our "V4V Products" tab for Vibez4Visionairiez
          sponsored products and merchandise.
        </li>
        <li>
          2. Donations also acceptable via cash app $JayZelsV4V or
          Paypal.me/vibez4visionairiez (donate button below)
        </li>

        <form
          action='https://www.paypal.com/donate'
          method='post'
          target='_top'
        >
          <input type='hidden' name='hosted_button_id' value='D32897NJDBYZ6' />
          <input
            type='image'
            src='https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif'
            border='0'
            name='submit'
            title='PayPal - The safer, easier way to pay online!'
            alt='Donate with PayPal button'
          />
          <img
            alt=''
            border='0'
            src='https://www.paypal.com/en_US/i/scr/pixel.gif'
            width='1'
            height='1'
          />
        </form>

        <li>
          3. Find @Vibez4Visionairiez on all social media platforms. Like,
          share, and subscribe! Bless! 🙏🏽💯✊🏾
        </li>
      </div>

      {/* 
      <div className='about-us-middle-section '>
        <div className='middle-section-image-container'>
          <img src='http://trydo.rainbowit.net/assets/images/about/finding-us-01.png' />
        </div>
        <div className='middle-section-detail'>
          <h3>Find Yout Work Now</h3>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that.
          </p>
          <div className='middle-section-btn'>Get Started</div>
        </div>
      </div> */}
      <Footer props={props} />
    </div>
  );
}
