import React, { useState } from 'react';
import './divider.css';

import { Carousel } from 'antd';

export default function Divider({ title }) {
  const crouselList = [
    require('../../assets/sliding images/1.jpg'),
    require('../../assets/sliding images/2.jpg'),
    require('../../assets/sliding images/3.jpg'),
    require('../../assets/sliding images/4.jpg'),
    require('../../assets/sliding images/5.jpg'),
    require('../../assets/sliding images/6.jpg'),
    require('../../assets/sliding images/7.jpg'),
    require('../../assets/sliding images/8.jpg'),
    require('../../assets/sliding images/9.jpg'),
    require('../../assets/sliding images/10.jpg'),
    require('../../assets/sliding images/11.jpg'),
    require('../../assets/sliding images/12.jpg'),
    require('../../assets/sliding images/13.jpg'),
    require('../../assets/sliding images/14.jpg'),
  ];
  return (
    <>
      <div className='slide-container'>
        <div className='divider-container'>
          <p>"Raising Vibrations ONE Artist at a Time..."</p>
        </div>
        <Carousel autoplay>
          {crouselList.map((val, i) => (
            <div className='slide-image-container'>
              <img src={val} alt={val} key={i} />
            </div>
          ))}
        </Carousel>
        <div className='divider-container'>
          <p>"Visionairy Highlights"</p>
        </div>
      </div>
    </>
  );
}
