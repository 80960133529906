import React, { useState } from 'react';
import Banner from '../../components/banner/banner';
import Header from '../../components/Header/header';
import { Icon } from 'react-icons-kit';
import { headphones } from 'react-icons-kit/icomoon/headphones';
import { mail } from 'react-icons-kit/icomoon/mail';
import { location } from 'react-icons-kit/icomoon/location';
import './contact.css';
import Footer from '../../components/Footer/footer';

export default function ContactUs(props) {
  return (
    <div>
      <Header props={props} />
      <Banner />
      <div className='contact-container mobile-H laptop-H desktop-H '>
        <div className='contact-detail'>
          <div className='contact-box'>
            <div className='contact-social-icon'>
              <Icon icon={headphones} size={30} />
            </div>
            <h5 className='box-heading'>Contact With Phone Number</h5>
            <div className='box-text'>+057 254 365 456 </div>
            <div className='box-text'>+856 325 652 984 </div>
          </div>
          <div className='contact-box'>
            <div className='contact-social-icon'>
              {' '}
              <Icon icon={mail} size={30} />
            </div>
            <h5 className='box-heading'>Email Address</h5>
            <a href='mailto:Info@vibez4visionairiez.com' className='box-text'>
              Info@vibez4visionairiez.com
            </a>
            <a
              href='mailto:Submissions@vibez4visionairiez.com'
              className='box-text'
            >
              Submissions@vibez4visionairiez.com
            </a>
            <a
              href='mailto:JavonBenzel@vibez4visionairiez.com'
              className='box-text'
            >
              JavonBenzel@vibez4visionairiez.com
            </a>
          </div>
          <div className='contact-box'>
            <div className='contact-social-icon'>
              {' '}
              <Icon icon={location} size={30} />
            </div>
            <h5 className='box-heading'>Location</h5>
            <div className='box-text'>5678 Bangla Main Road, cities 580</div>
            <div className='box-text'>GBnagla, example 54786 </div>
          </div>
        </div>
      </div>

      {/* <Banner /> */}
      <Footer props={props} />
    </div>
  );
}
